export default [
  {
    path: '/contrato/consulta',
    name: 'consulta_contrato',
    component: () => import('@/views/contrato/consulta/ConsultaContrato.vue'),
    meta: {
      requireAuth: true,
    },
  },
  //   {
  //     path: '/cliente',
  //     name: 'novo_cliente',
  //     component: () => import('@/views/cliente/form/FormCliente.vue'),
  //     meta: {
  //       requireAuth: true,
  //     },
  //   },
  {
    path: '/contrato/:id',
    name: 'detalhe_contrato',
    component: () => import('@/views/contrato/form/FormContrato.vue'),
    meta: {
      requireAuth: true,
    },
  },
];
